/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
// Pjax Setup

class Pjax {
  static targetContainer(containerID) {
    return `[data-pjax-container='${containerID}']`
  }

  static dataPjaxClick(e) {
    const $dispatchEl = $(this).closest("[data-pjax-click]")
    const containerName = $dispatchEl.data("pjax-click")
    const selector = Pjax.targetContainer(containerName)
    const options = { container: selector }
    if (
      containerName === "modal" ||
      containerName === "modal-content" ||
      containerName === "preview" ||
      $dispatchEl.data("pjax-push") === false
    ) {
      options.push = false
      options.scrollTo = false
    }
    if ($dispatchEl[0].hasAttribute("data-pjax-type")) {
      options.type = $dispatchEl.data("pjax-type")
    }
    if ($dispatchEl[0].hasAttribute("data-pjax-fragment")) {
      options.fragment = selector
    }
    $.pjax.click(e, options)
    if ($dispatchEl.data("pjax-async") !== false) {
      $.pjax.xhr = null
    }
    return $dispatchEl.trigger("pjax:started")
  }

  static render(element, params) {
    params ??= {}
    const dataAttribute = "data-pjax-render"
    const $element = $(element)
    const url = $element.attr(dataAttribute)
    const selector = `[${dataAttribute}='${url}']`

    let options = { url, container: selector, push: false }
    if ($element.parents(".modal").length > 0) {
      options.scrollTo = false
    }
    options = $.extend(options, params)

    $.pjax(options)
    return ($.pjax.xhr = null)
  }
}

$.onmount("[data-pjax-click] a, a[data-pjax-click]", function () {
  return $(this).on("click", Pjax.dataPjaxClick)
})

$.onmount("[data-pjax-reload] a, a[data-pjax-reload]", function () {
  return $(this).on("click", function (e) {
    e.preventDefault()
    const $dispatchEl = $(this).closest("[data-pjax-reload]")
    const containerName = $dispatchEl.data("pjax-reload")
    const selector = Pjax.targetContainer(containerName)
    const options = { container: selector }
    if ($dispatchEl[0].hasAttribute("data-pjax-fragment")) {
      options.fragment = selector
    }
    return $.pjax.reload(options)
  })
})

$.onmount("form[data-pjax-submit]", function () {
  return $(this).on("submit", function (e) {
    const data = $(this).data()

    const selector = Pjax.targetContainer(data.pjaxSubmit)
    const options = $.extend(
      { container: selector, push: false },
      "pjaxFragment" in data ? { fragment: selector } : {},
      {
        type: data.pjaxType,
        replace: data.pjaxReplace,
        scrollTo: data.pjaxScrollTo,
        refreshData: data.refreshData,
      },
    )
    if (data.pjaxSubmit === "modal-content" && data.followUpForm) {
      options.customSuccess = () => $(".modal-overlay").scrollTop(0)
    }

    if (data.pjaxSubmitConfirm) {
      const message = data.pjaxSubmitConfirm
      if (!confirm(message)) {
        return e.preventDefault()
      }
    }

    return $.pjax.submit(e, options)
  })
})

$.onmount("[data-pjax-render]", function () {
  return Pjax.render(this)
})

$.onmount("[data-pjax-rerender]", function () {
  const $element = $(this)
  return $element.click(() =>
    $element.closest("[data-pjax-container]").one("pjax:end", () => {
      const containerName = $element.data("pjax-rerender")
      const selector = Pjax.targetContainer(containerName)
      const $container = $(selector)
      const url = $container.data("pjax-render") ?? $container.data("pjax-render-path")
      if (url !== undefined) {
        return $.pjax({
          url,
          container: selector,
          push: false,
        })
      }
      return $container.trigger("pjax:rerender")
    }),
  )
})

// Progress bar at top of page
NProgress.configure({
  showSpinner: false,
  trickleRate: 0.05,
  trickleSpeed: $.pjax.defaults.timeout / 20,
})
$(document).on("pjax:send.nprogress", NProgress.start)

$(document).on("pjax:complete.nprogress", (_xhr, _options) => {
  NProgress.done()
  return $.onmount()
})

$.pjax.defaults.timeout = 1200
$(document).on(
  "pjax:timeout",
  (
    e, // Prevent default timeout redirection behavior
  ) => e.preventDefault(),
)

$(document).on("pjax:success", (_e, _data, _status, _xhr, options) => {
  // run "customSuccess" method passed to PJAX if it exists
  if (typeof options.customSuccess === "function") {
    options.customSuccess()
  }

  if (options.target?.tagName?.toUpperCase() !== "A") {
    return
  }
  const $link = $(options.target)
  if (!($link.parents(".tabs-container").length > 0)) {
    return
  }
  $link.siblings(".active").removeClass("active")
  // for links in the 'more' dropdown
  $link.parents(".tabs-container").find(".active:not(.tab-dropdown-link)").removeClass("active")
  return $link.addClass("active")
})

// eslint-disable-next-line @typescript-eslint/no-unused-vars
$(document).on("pjax:error", (e, xhr, _textStatus, _error, _options) => {
  e.preventDefault() // kills full page reloads
  xhr.abort()
  return console.error("pjax:error - xhr aborted", xhr)
})

$(document).on(
  "pjax:beforeReplace",
  (
    e, // see: https://github.com/reactjs/react-rails#event-handling
  ) =>
    // see also: https://github.com/reactjs/react-rails/pull/490/files
    window?.ReactRailsUJS?.unmountComponents(e.target),
)

$(document).on("ready pjax:end", (e, xhr) => {
  const container = e.target
  // We avoid re-mounting components if the pjax request was aborted.
  if (xhr?.statusText !== "abort") {
    window?.ReactRailsUJS?.mountComponents(container)
  }

  // Reinitializes tinymce on new page content
  // see: https://github.com/defunkt/jquery-pjax#reinitializing-pluginswidget-on-new-page-content
  const $tinymceEditors = $(container.querySelectorAll(".tinymce"))
  return $tinymceEditors.each(function () {
    const editor = tinymce.get(`${this.id}`)
    if (!editor) {
      return
    }
    const configOptions = $.extend({}, window.tinymceDefaults, {
      auto_focus: editor.options.get("auto_focus"),
      readonly: editor.options.get("readonly"),
    })
    tinymce.remove(editor)
    return tinymce.init(configOptions)
  })
})

window.Pjax = Pjax

/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
// Utility methods
window.App = window.App || {}

App.Helpers = class Helpers {
  // https://stackoverflow.com/questions/12006095/javascript-how-to-check-if-character-is-rtl
  static isRTL(s) {
    const ltrChars = "A-Za-zÀ-ÖØ-öø-ʸ̀-֐ࠀ-῿Ⰰ-﬜﷾-﹯﻽-￿"
    const rtlChars = "\u0591-\u07FF\uFB1D-\uFDFD\uFE70-\uFEFC"
    const rtlDirCheck = new RegExp(`^[^${ltrChars}]*[${rtlChars}]`)
    return rtlDirCheck.test(s)
  }

  /* l10n and i18n helpers */

  // Returns an object that describes how monetary values are formatted for the
  // given locale and currency. Uses fallback locale code and currency code.
  //
  // This can be useful for parsing currency values into proper numbers.
  //
  // @example
  //
  //   App.Helpers.explainCurrency(currency: 'EUR', locale: 'fr')
  //   #=> { ..., decimal: ',', separator: ' ', symbol: '€', ... }
  static explainCurrency(options) {
    options ??= {}
    const locale = options.locale || window.gon?.currency_locale || "en"
    const currency = options.currency || window.gon?.currency_code || "USD"
    const notation = options.notation || "standard"

    // Check cache for currency details
    this.cache ??= {}
    if (this.cache[`${locale}-${currency}-${notation}`]) {
      return this.cache[`${locale}-${currency}-${notation}`]
    }

    const formatter = new Intl.NumberFormat(locale, {
      style: "currency",
      currency,
    })

    const parts = formatter.formatToParts(82890.31)

    return (this.cache[`${locale}-${currency}-${notation}`] = {
      formatter,
      currency,
      locale,
      symbol: parts.find((val) => val.type === "currency")?.value,
      decimal: parts.find((val) => val.type === "decimal")?.value,
      separator: parts.find((val) => val.type === "group")?.value,
      demo: {
        str: formatter.format(82890.31),
        arr: formatter.formatToParts(82890.31),
      },
    })
  }

  // Returns a number that represents `val`, which should be a string. Forwards
  // numerics. Dies painfully with `null` or `undefined`. Or it just forwards the
  // despair back to the caller.
  //
  // Currency and locale can be provided as options, otherwise this falls back to
  // the settings in `window.gon`. Note that the first example below assumes an
  // account with locale 'fr' and currency 'EUR'.
  //
  // @example
  //
  //   parseCurrency('82 890,31 €') #=> 82890.31
  //   parseCurrency('$500.00', { locale: 'en', currency: 'USD' }) #=> 500
  static parseCurrency(val, options) {
    options ??= {}
    if (val == null) {
      return val
    }
    if (typeof val === "number") {
      return val
    }
    let rval = val

    const details = this.explainCurrency(options)
    const regex = new RegExp(`[^0-9${details.decimal || ""}]`, "g")
    if (this.isRTL(val)) {
      const rtlRegex = new RegExp("[\u0591-\u07FF]|[\uFB1D-\uFDFD]|[\uFE70-\uFEFC]", "g")
      rval = val.replace("د.إ", "").replace(rtlRegex, "")
    }
    const nextStr = rval.replace(regex, "")
    if (details.decimal === "." || details.decimal === "") {
      return Number(nextStr)
    }

    return Number(nextStr.replace(details.decimal, "."))
  }

  // Formats `val`, provided it is a number or a string representing a number, as
  // a currency.
  //
  // When +trailing+ is true, whole numbers will display zeros as their fraction
  // digits. Otherwise, for whole numbers, fraction digits will be dropped.
  //
  // @example
  //
  //   formatCurrency(50020.1) #=> 50 020,10 €
  //   formatCurrency(50020.0) #=> 50 020 €
  //   formatCurrency(50020.0, trailing: true) #=> 50 020,00 €
  static formatCurrency(val, options) {
    options ??= {}
    if (val == null || val === "") {
      return ""
    }
    if (Number.isNaN(Number(val))) {
      return val
    }

    const locale = options.locale || window.gon?.currency_locale || "en"
    const uniqueSymbol = options.uniqueSymbol || window.gon?.useUniqueCurrencySymbol || false
    const fmtOptions = {
      style: "currency",
      currency: options.currency || window.gon?.currency_code || "USD",
      notation: options.notation || "standard",
      compactDisplay: options.compactDisplay || "short",
    }
    const symbol = this.symbolFor(fmtOptions.currency, uniqueSymbol)

    if (!options.trailing && Number.isInteger(Number(val))) {
      fmtOptions.minimumFractionDigits = 0
      fmtOptions.maximumFractionDigits = 0
      fmtOptions.trailingZeroDisplay = "stripIfInteger"
    }

    let parts = new Intl.NumberFormat(locale, fmtOptions).formatToParts(val)
    if (options.omitSymbol) {
      parts = parts.filter((val) => val.type !== "currency")
    }
    let currency_i = 0
    parts = parts.map((val, i) => {
      if (val.type === "currency") {
        currency_i = i + 1
        return { type: "currency", value: symbol }
      }
      if (val.type === "literal" && val.value.trim().length === 0 && currency_i > 0) {
        // The ruby number formatter does not add a space here
        return { type: "literal", value: "" }
      }
      return val
    })
    return parts
      .map((val) => val.value)
      .join("")
      .trim()
  }

  /* File helpers */

  // Fetches path to assets
  //
  // @example
  //
  //   App.Helpers.imagePath('logo.png') #=> '/static-assets/logo.png'
  static imagePath(filename) {
    return window.assets[filename] || `/static-assets/${filename}`
  }

  static symbolFor(currencyCode, unique) {
    let symbol
    const definition = App.Symbols.currencyDefinitions()[currencyCode]
    const hasDisambig = definition.disambiguation !== null
    return (symbol = unique && hasDisambig ? definition.disambiguation : definition.symbol)
  }
}

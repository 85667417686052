const $ = window.jQuery
const moment = window.moment
const dummyForm = `<div>
  <a class="list-group-item-close" data-action="close-edit" href="#">
    <i class="far fa-xmark" aria-hidden="true"></i>
  </a>
  <div class="input-group">
    <label>Select File</label>
    <div class="input-file">
      <label for="new_file" class="btn--large btn--secondary !font-bold">
        <i class="far fa-upload"></i>
        <span>Choose File</span>
      </label>
      <input id="new_file" type="file" name="job_application[job_application_files_attributes][][file]">
    </div>
  </div>
  <div class="input-group">
    <label for="job_application_file[file]">
    File Title (Optional)
    </label>
    <input id="file_title" class="input" type="text" name="job_application[job_application_files_attributes[][title]" value="">
  </div>
  <a class="btn--large btn--primary" data-action="append-uploaded-file-row">Save</a>
</div>`

function modifyUploadForm(title, fileInput, formId) {
  const form = $.parseHTML(dummyForm)
  $(form).attr("id", `form_${formId}`)
  $(form).find("a[data-action=close-edit]").attr("data-action", "discard-edit")
  $(form)
    .find("a[data-action=append-uploaded-file-row]")
    .attr("data-action", "update-uploaded-file-row")

  if (title) {
    $(form).find(":input#file_title").val(title)
  }

  if (fileInput) {
    $(form).find(":input[type=file]").replaceWith(fileInput.attr("id", "new_file"))
  }

  return form
}

function replaceTitle() {
  $(":input#new_file").on("change", (e) => {
    const file = e.currentTarget.files[0]
    const titleField = $(e.currentTarget).closest(".list-group-item").find(":input#file_title")

    titleField.val(file.name)
  })
}

function closeEditForm(e) {
  $(e.currentTarget).closest(".list-group-item").remove()
  $("[data-action=add-file]").show()
}

function generateRandomFormID() {
  const id = Math.floor(Math.random() * 100)

  if ($(`div#${id}`).size() > 0) {
    generateRandomFormID()
  }
  return id
}

function discardChanges(e) {
  const formWrapper = $(e.currentTarget).closest("div")
  const parent = formWrapper.closest(".list-group-item")
  const id = formWrapper.attr("id").split("_")[1]
  const edit = $(`div#row_${id}`)

  parent.html(edit.children())
  edit.remove()

  initializeDropdownLinks()
}

function applyChanges(e) {
  const formWrapper = $(e.currentTarget).closest("div")
  const id = formWrapper.attr("id").split("_")[1]
  const row = $(`div#row_${id}`)

  const updatedTitle = formWrapper.find(":input#file_title").val()
  const updatedFile = formWrapper.find(":input#new_file")

  row.find("a.list-group-item-title").text(updatedTitle)
  row.find("div#hidden-inputs").find("input[type=hidden]").val(updatedTitle)
  row.find("div#hidden-inputs").find(":input[type=file]").replaceWith(updatedFile.removeAttr("id"))

  formWrapper.closest(".list-group-item").replaceWith(row.removeAttr("id").show())
  row.find("div[data-wrapper=title]").html(renderTitle(updatedTitle, updatedFile[0].files[0].name))

  initializeDropdownLinks()
}

function openEditForm(e) {
  const rowDiv = $(e.currentTarget).closest(".list-group-item")
  const fileRowWithoutChanges = rowDiv.clone()

  const title = rowDiv.find(":input[type=hidden]").val()
  const fileInput = rowDiv.find(":input[type=file]")
  const formId = generateRandomFormID()
  const editForm = modifyUploadForm(title, fileInput, formId)

  fileRowWithoutChanges.attr("id", `row_${formId}`).hide()
  fileRowWithoutChanges.appendTo(rowDiv.parent())

  rowDiv.html(editForm)

  replaceTitle()
  $("[data-action=discard-edit]").on("click", discardChanges)
  $("[data-action=update-uploaded-file-row]").on("click", applyChanges)
}

function removeFileRow(e) {
  $(e.currentTarget).closest(".list-group-item").remove()
}

function renderTitle(title, fileName) {
  if (title === fileName) {
    return `<a class="list-group-item-title" href="#" data-action="preview">${title}</a>`
  }
  return `<a class="list-group-item-title" href="#" data-action="preview">${title}</a>
    <div class="list-group-item-subtitle">${fileName}</div>`
}

function initializeDropdownLinks() {
  $.onmount(".dropdown .dropdown-link")
  $("[data-action=edit-uploaded-file]").on("click", openEditForm)
  $("[data-action=remove-file-row]").on("click", removeFileRow)
}

function insertFileRow(e) {
  const fileTitle = $(e.currentTarget.parentElement).find(":input#file_title").val()
  const fileInput = $(e.currentTarget.parentElement).find(":input#new_file:last")
  fileInput.removeAttr("id")

  const fileRow = `
  <div class="list-group-item">
    <span class="dropdown">
      <a class="dropdown-link">
        <i class="fa fa-caret-down" aria-hidden="true"></i>
      </a>
      <div class="dropdown-menu" style="display: none;">
        <a class="dropdown-menu-link" href="#" data-action="edit-uploaded-file">
          Edit
        </a>
        <a class="dropdown-menu-link" href="#" data-action="remove-file-row">
          Delete
        </a>
      </div>
    </span>
    <div data-wrapper="title">
      ${renderTitle(fileTitle, fileInput[0].files[0].name)}
    </div>
    <div class="secondary-text">
      ${moment().format("M/DD/YYYY")}
    </div>
    <div style="display:none" id="hidden-inputs">
      <input type="hidden" name="job_application[job_application_files_attributes][][title]" value='${fileTitle}'>
    <div>
  </div>
  `

  $(e.currentTarget).parents().find(".list-group.mb-4:last").append(fileRow)
  $("div#hidden-inputs:last").append(fileInput)
  initializeDropdownLinks()
  closeEditForm(e)
}

function openUploadForm(e) {
  $(e.currentTarget).hide()
  const wrapperUploadBlockDiv = $(e.currentTarget.closest(".mb-4")).find(".list-group.mb-4")

  const $list = $("<div class='list-group-item'></div>")
  $list.appendTo(wrapperUploadBlockDiv)
  $list.html(dummyForm)
  replaceTitle()

  $("[data-action=close-edit]").on("click", closeEditForm)
  $("[data-action=append-uploaded-file-row]").on("click", insertFileRow)
}

function hideAddFileButton(e) {
  $(e.currentTarget).remove()
}

$.onmount(".list-group-item", () => {
  $("[data-action=close-edit]").click(closeEditForm)
})

$.onmount(".files .add-btn, #file-manager .add-btn", () => {
  $("[data-action=add-file]").click(openUploadForm)
})

$.onmount(".files .add-btn, #file-manager .add-btn", () => {
  $("[data-action=hide-add-file-button]").click(hideAddFileButton)
})

$.onmount("#application-file", function () {
  $(this).on("change", (e) => {
    const file = e.currentTarget.files[0]
    const titleField = $("#job_application_file_title")

    titleField.val(file.name)
  })
})

/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
const updateCount = function (count) {
  $("#list-member-count").text(count)
  if (count === 1) {
    return $("#list-member-text").text("List Member".t("org_chart"))
  } else {
    return $("#list-member-text").text("List Members".t("org_chart"))
  }
}

const removeListPersonRow = function (e) {
  $(e.currentTarget).parents("tr").remove()
  validateForm()

  return updateCount(parseInt($("#list-member-count").text()) - 1)
}

const addListPersonRow = function (personId, personFullName) {
  const newRow = `\
<tr id=${personId}> \
<td> \
${personFullName} \
<input type='hidden' name='list[list_people][][person_id]' value=${personId}> \
</td> \
<td> \
<input class='input' type='text' name='list[list_people][][role]' id='list_list_people_role'> \
</td> \
<td> \
<a data-action='remove-list-person' data-person-id=${personId}> \
<i class='far fa-xmark' aria-hidden='true'></i> \
</a> \
</td> \
</tr>`

  $("#list-people #list-people-list").append(newRow)
  $("#list-people")
    .find(".list-group-item:last")
    .find("a[data-action='remove-list-person']")
    .on("click", removeListPersonRow)

  updateCount(parseInt($("#list-member-count").text()) + 1)

  return $(`#${personId} a[data-action='remove-list-person']`).on("click", removeListPersonRow)
}

const addListPersonClick = function () {
  const $input = $("#list-person-autocomplete")

  addListPersonRow($input.data("person-id"), $input.data("person-full-name"))

  $input.removeData("person-id")
  $input.removeData("person-full-name")

  $("#list-person-autocomplete").val("")
  return $("button[data-action='add-list-person']").off("click")
}

const emptyErrors = function () {
  $("#list-people .input-group").removeClass("form-error")
  $("#list_list_people_person_error").removeClass("form-error-message")
  return $("#list_list_people_person_error").empty()
}

const listPeople = () =>
  $("#list-people-list td input[type=hidden]").map(function () {
    return this.value
  })

const validateListPerson = function () {
  const $input = $("#list-person-autocomplete")

  const personId = $input.data("person-id")
  const personFullName = $input.data("person-full-name")

  $("button[data-action='add-list-person']").off("click")

  if (!personId) {
    return false
  }

  const $error = $("#list-people #list_list_people_person_error")
  if (listPeople().toArray().includes(personId.toString())) {
    $error.addClass("input-help-text form-error-message")
    $error.append(`${personFullName} has already been added as a member of this list.`)
    $("#list-people .input-group").addClass("form-error")
    return false
  } else {
    $("button[data-action='add-list-person']").on("click", addListPersonClick)
    return true
  }
}

var validateForm = function () {
  emptyErrors()
  return validateListPerson()
}

$.onmount("#list-people", function () {
  $(this).on("autocomplete:inputChanged", function (obj) {
    const $input = $("#list-person-autocomplete")
    const inputValue = $input.val()
    const personFullName = $input.data("person-full-name")

    if (inputValue !== personFullName) {
      $input.removeData("person-id")
      $input.removeData("person-full-name")

      return validateForm()
    }
  })

  $(this).on("autocomplete:selectSuggestion", function (obj, $selected) {
    const personId = $selected.data("id")
    const personFullName = $selected.find(".list-group-item-title").text().trim()
    const $input = $("#list-person-autocomplete")

    $input.data("person-id", personId)
    $input.data("person-full-name", personFullName)

    return validateForm()
  })

  $("a[data-action='remove-list-person']").on("click", removeListPersonRow)

  return $(this).on("keydown", function (e) {
    if (Keyboard.keyMatchesName(e.which, "enter")) {
      e.preventDefault()
      if (validateListPerson()) {
        addListPersonClick()
      }
      return validateForm()
    }
  })
})

$.onmount("form.new_list", () =>
  $("form.new_list input[type=submit]").on("click", function (e) {
    if ($("form.new_list .form-error-message.ui-error").length) {
      e.preventDefault()
    }

    if (window.location.pathname.indexOf($("form.new_list").attr("action")) > 0) {
      return $("[data-pjax-container=lists]").one("pjax:end", () =>
        App.OrgChart.getChart().reloadData(),
      )
    }
  }),
)

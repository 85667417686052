import axios from "axios"
import dayjs from "dayjs"

import { tableToCSV, tableToCSVData } from "v2/helpers/csv"
import { buildCsrfHeader } from "v2/httpUtils"
import { UrlHelper } from "v2/react/utils/urls"

/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
$.onmount("[data-action=export]", function () {
  return $(this).on("click", async function (e) {
    const selector = $(this).data("table")
    const givenFilename = $(this).data("export-name")
    const baseFilename =
      givenFilename ||
      $("h1")
        .first()
        .text()
        .toLowerCase()
        .replace(/[\s\/]/gi, "-")
    const filename = `built-${baseFilename}-${dayjs().format("YYYY-MM-DD")}.csv`
    const csvData = tableToCSVData($(selector))
    const exportType = $(this).data("report-type") || "generic"
    const data = {
      csvContent: csvData,
      filename,
      exportType,
      filetype: "csv",
    }

    const downloadCSV = () => tableToCSV($(selector), filename, e.currentTarget)

    const exportPromise = axios.post(UrlHelper.exportsPath(), data, {
      headers: buildCsrfHeader(),
    })

    if (!window.gon?.enable_async_exports) {
      return downloadCSV()
    }
    try {
      const createExportResponse = await exportPromise
      if (createExportResponse?.status === 202) {
        window.location.href = UrlHelper.exportsPath()
      } else {
        downloadCSV()
      }
    } catch (error) {
      if (window.Sentry) window.Sentry.captureException(error)
      downloadCSV()
    }
  })
})

$.onmount("[data-action=export-modal]", function () {
  return $(this).on("click", (e) => {
    if ($(".modal .pagination").length > 0 && $(e.target).data("export") !== "page") {
      $(".export-options").show()
      return false
    }

    const baseFilename = $(".modal-title").first().text().toLowerCase().replace(/\s/gi, "-")
    const filename = `built-${baseFilename}-${dayjs().format("YYYY-MM-DD")}.csv`
    tableToCSV($(".modal table"), filename, e.currentTarget)
    return $(".export-options").hide()
  })
})

$.onmount("[data-action=close-export]", function () {
  return $(this).on("click", (e) => {
    e.preventDefault()
    $(".export-options").hide()
    return $(".export-all-message").hide()
  })
})

$.onmount("[data-action=export-all]", function () {
  return $(this).on("click", () => {
    $(".export-options").hide()
    return $(".export-all-message").show()
  })
})

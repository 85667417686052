import throttle from "lodash/throttle"

const $ = window.jQuery
const progressBarSelector = "[data-pjax-container=progress]"
const $progressBarContainer = $(progressBarSelector)
const pjaxUrl = $progressBarContainer.data("pjax-url")

const pollForProgress = throttle(
  () => {
    $.pjax({
      url: pjaxUrl,
      container: progressBarSelector,
      push: false,
      scrollTo: false,
    })
  },
  1000,
  { leading: false, trailing: true },
)

const redirectOrCheckAgain = (e) => {
  const $progressElement = $(e.currentTarget).find("[data-progress]")
  const shouldRedirect = $progressElement.data("redirect")
  if (shouldRedirect) {
    const redirectUrl = $progressElement.data("redirect-url")
    window.location = redirectUrl
    return
  }

  pollForProgress()
}

const disableNprogressBar = () => {
  $(document).off("pjax:send.nprogress")
  $(document).off("pjax:complete.nprogress")
}

const startProgressBar = () => {
  disableNprogressBar()
  pollForProgress()
  $progressBarContainer.on("pjax:complete", redirectOrCheckAgain)
}

function fillParentId() {
  $(this).on("autocomplete:selectSuggestion", (_obj, $selected) => {
    $("[name*=parent_id]").val($selected.data("id"))
  })
}

$.onmount("#imports-progress", startProgressBar)
$.onmount("#import_select_reports_to", fillParentId)

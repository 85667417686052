const $ = window.jQuery

const MountPoints = Object.freeze([
  ".edit-ftp-settings-form",
  ".edit-adp-settings-form",
  ".edit-adp-ihcm-settings-form",
  ".edit-lever-settings-form",
  ".edit-greenhouse-settings-form",
  ".edit-isolved-settings-form",
  ".edit-paylocity-settings-form",
  ".edit-smart_recruiters-settings-form",
])

function toggleMoreOptions() {
  const $form = $(this).closest("form")
  const $checked = $form.find(".with-hidden-options input:checked")
  const showMore = $checked.val() === "true"
  $form.find(".card").toggle(showMore)
}

function toggleFrequencyOptions() {
  const $selectedOption = $(this).val()
  $(".day-of-week").toggle($selectedOption === "weekly")
  $(".daily-frequency").toggle($selectedOption === "daily")
}

function updateDailyFrequencyTooltip() {
  const $selectedOption = $(this).val()
  $("#daily-frequency-tooltip")
    .find("text")
    .html(`daily_frequency_info_${$selectedOption}`.t("import_settings"))
}

function testConnection(e) {
  if ($(this).hasClass("disabled")) {
    return
  }
  e.preventDefault()

  const pjaxUrl = $(this).data("pjax-url")
  const startingText = $(this).text()
  const data = $(this)
    .closest("form")
    .find("[name*=ftp_account], [name*=csv_folder_path]")
    .serializeArray()
  const resultsSelector = "[data-pjax-container=connection-test-results]"
  const $resultsContainer = $(resultsSelector)

  $resultsContainer.empty()
  $(this).addClass("disabled").attr("disabled", true).text("testing_disabled".t("button_defaults"))

  $.pjax({
    url: pjaxUrl,
    data,
    container: resultsSelector,
    push: false,
    scrollTo: false,
    type: "POST",
  }).always(() => {
    $(this).removeClass("disabled").attr("disabled", false).text(startingText)
  })
}

function bootstrapImportSettings() {
  const $importSettings = $(this)

  const $toggleMoreOptions = $importSettings.find(".with-hidden-options input")
  $toggleMoreOptions.on("change", toggleMoreOptions)
  $toggleMoreOptions.trigger("change")

  const $importFrequencySelector = $importSettings.find('[name*="import_frequency"]')
  $importFrequencySelector.on("change", toggleFrequencyOptions)
  $importFrequencySelector.trigger("change")

  const $dailyFrequencySelector = $importSettings.find('[name*="daily_frequency"]')
  $dailyFrequencySelector.on("change", updateDailyFrequencyTooltip)

  const $testConnectionButton = $importSettings.find('[data-action="test-connection"]')
  $testConnectionButton.on("click", testConnection)
}

$.onmount(MountPoints.join(", "), bootstrapImportSettings)

/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
window.escapeForCSV = function (text) {
  text = text.replace(/"/g, '""')
  if (/^[=\+\-@]/.test(text)) {
    text = "'" + text
  }
  if (/[",]/.test(text)) {
    text = `\"${text}\"`
  }

  return text
}

const tableToCSVData = function ($table) {
  const $rows = $table.find("tr:has(td), tr:has(th)")
  const rows = $rows.map((i, row) => {
    const $row = $(row)
    if ($row.hasClass("no-print")) {
      return ""
    }
    const $columns = $row.find("td,th")
    const columns = $columns.map(function (j, column) {
      const $column = $(column)
      let text =
        $column
          .children(":not(.no-print)")
          .map(function () {
            return $(this).text()
          })
          .get()
          .join(", ") || $column.text()
      text = text.trim().replace(/\s*\n\s*/, " ")
      text = text.replace("–", "-")

      if (!$column.hasClass("no-print")) {
        return window.escapeForCSV(text)
      }
    })

    return columns.get().join(",")
  })

  return rows.get().join("\r\n")
}

const tableToCSV = function ($table, filename, link) {
  const csvData = tableToCSVData($table)
  const dataEncodedContents = "data:application/csv;charset=utf-8," + encodeURIComponent(csvData)

  return $(link).attr({
    download: filename,
    href: dataEncodedContents,
  })
}

window.tableToCSV = tableToCSV

export { tableToCSVData, tableToCSV }

const $ = window.jQuery
const _ = window._
const Keyboard = window.Keyboard

function sourceRow(name, value) {
  return `<li class="list-group-item">
            <span>${value}</span>
            <input name="${name}" type="hidden" value="${value}">
            <a href="#" data-action="delete">
              <i class="far fa-xmark" aria-hidden="true"></i>
            </a>
          </li>`
}

function hideJobSourcesContainer() {
  return $(".job-sources").addClass("hidden")
}

function showJobSourcesContainer() {
  return $(".job-sources").removeClass("hidden")
}

function removeRow(e) {
  if (e != null) {
    e.preventDefault()
  }
  e.currentTarget.parentElement.remove()
  if ($(".job-sources li").length === 0) {
    hideJobSourcesContainer()
  }

  return false
}

function addRow(name, value) {
  if ($(".job-sources").hasClass("hidden")) {
    showJobSourcesContainer()
  }
  $("ul.job-sources").append(sourceRow(name, value))
  $("ul.job-sources li:last a[data-action=delete]").on("click", removeRow)
}

function addSource(e, inputName) {
  e.preventDefault()

  if (
    Keyboard.keyMatchesName(e.which, "enter") ||
    e.currentTarget.getAttribute("data-action") === "add-source"
  ) {
    const jobSource = $("input[name=job_source]")
    const jobSourceValue = jobSource.val().trim()
    if (jobSourceValue.length) {
      addRow(inputName, jobSourceValue)
      jobSource.val("")
      jobSource.focus()
    }
  }
  return false
}

function submitOnEmpty() {
  // prevent form submission via Enter key when value is present in input field
  if (_.isEmpty($("input[name=job_source]").val())) {
    return $("form").submit()
  }
  return false
}

function addEventListeners(inputName) {
  $("input[name=job_source]").keyup((e) => {
    addSource(e, inputName)
  })
  $("[data-action=add-source]").click((e) => {
    addSource(e, inputName)
  })
  $("[data-action=delete]").click(removeRow)
  $("[data-action=save]").click(submitOnEmpty)
}

$.onmount("#job-sources", () => {
  addEventListeners("company[job_setting_attributes][job_sources][]")
})

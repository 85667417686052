/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
const removeListPersonRow = function (e) {
  $(e.currentTarget).parents(".list-group-item").remove()
  return updateExcludeList()
}

const addPositionTypeRow = function (e, $suggestion) {
  const newRow = `\
<div class='list-group-item' data-id='${$suggestion.data("id")}'> \
${$suggestion.text()} \
<input type='hidden' name='custom_field[position_type_ids][]' value='${$suggestion.data("id")}'> \
<a href='#' class='remove' data-action='remove-position-type-row'><i class='far fa-xmark'></i></a> \
</div>`

  $("#position-types-list").append(newRow)
  $("#position-types-list")
    .find(".list-group-item:last")
    .find("a[data-action='remove-position-type-row']")
    .on("click", removeListPersonRow)
  $("[name=position_type]").val("")
  return updateExcludeList()
}

var updateExcludeList = function () {
  const positionTypeIds = $("#position-types-list .list-group-item")
    .map(function () {
      return $(this).data("id")
    })
    .get()
  return $("[name=position_type]").data("exclude", positionTypeIds)
}

$.onmount("form[id*='new_custom_field']", function () {
  const $isRequiredInput = $('[name="custom_field[is_required]"')
  const $requiredForForm = $("#required-for")
  const $requiredList = $("#required-positions-list")

  $isRequiredInput.on("change", function () {
    if (this.value === "true") {
      return $requiredForForm.removeClass("hidden")
    } else {
      return $requiredForForm.addClass("hidden")
    }
  })

  $requiredForForm
    .find("input[type=radio][name='custom_field[required_for]']")
    .on("change", function () {
      if (this.value === "positions") {
        return $requiredList.removeClass("hidden")
      } else {
        return $requiredList.addClass("hidden")
      }
    })

  $("[name=position_type]").on("autocomplete:selectSuggestion", addPositionTypeRow)
  return $("#position-types-list a[data-action=remove-position-type-row]").on(
    "click",
    removeListPersonRow,
  )
})

window.App = window.App || {}
App.Symbols = class Symbols {
  static currencyDefinitions() {
    return {
  "AUD": {
        "icon": "far fa-dollar-sign",
        "symbol": "$",
        "disambiguation": "A$"
      },
      "AED": {
        "icon": "fas fa-uae-dirham-sign",
        "symbol": "د.إ",
        "disambiguation": null
      },
      "BGN": {
        "icon": "fas fa-lev-sign",
        "symbol": "лв.",
        "disambiguation": null
      },
      "BRL": {
        "icon": "far fa-brazilian-real-sign",
        "symbol": "R$",
        "disambiguation": null
      },
      "CAD": {
        "icon": "far fa-dollar-sign",
        "symbol": "$",
        "disambiguation": "C$"
      },
      "CHF": {
        "icon": "far fa-chf-sign",
        "symbol": "CHF",
        "disambiguation": null
      },
      "CLP": {
        "icon": "far fa-dollar-sign",
        "symbol": "$",
        "disambiguation": "CLP$"
      },
      "CNY": {
        "icon": "far fa-yen-sign",
        "symbol": "¥",
        "disambiguation": "CN¥"
      },
      "CRC": {
        "icon": "fas fa-colon-sign",
        "symbol": "₡",
        "disambiguation": null
      },
      "DKK": {
        "icon": "fas fa-danish-krone-sign",
        "symbol": "kr.",
        "disambiguation": "Dkr."
      },
      "EUR": {
        "icon": "far fa-euro-sign",
        "symbol": "€",
        "disambiguation": null
      },
      "GBP": {
        "icon": "far fa-pound-sign",
        "symbol": "£",
        "disambiguation": null
      },
      "HKD": {
        "icon": "fas fa-hong-kong-dollar-sign",
        "symbol": "HK$",
        "disambiguation": null
      },
      "HUF": {
        "icon": "fas fa-hungarian-forint-sign",
        "symbol": "Ft",
        "disambiguation": null
      },
      "ILS": {
        "icon": "fas fa-israeli-new-shekel-sign",
        "symbol": "₪",
        "disambiguation": null
      },
      "ISK": {
        "icon": "fas fa-icelandic-krona-sign",
        "symbol": "ISK",
        "disambiguation": null
      },
      "INR": {
        "icon": "far fa-indian-rupee-sign",
        "symbol": "₹",
        "disambiguation": null
      },
      "JPY": {
        "icon": "far fa-yen-sign",
        "symbol": "¥",
        "disambiguation": "JP¥"
      },
      "KGS": {
        "icon": "fas fa-som-sign",
        "symbol": "С̲",
        "disambiguation": null
      },
      "KRW": {
        "icon": "fas fa-won-sign",
        "symbol": "₩",
        "disambiguation": null
      },
      "MXN": {
        "icon": "far fa-dollar-sign",
        "symbol": "$",
        "disambiguation": "MX$"
      },
      "MYR": {
        "icon": "fas fa-ringgit-sign",
        "symbol": "RM",
        "disambiguation": null
      },
      "NOK": {
        "icon": "fas fa-kr-sign",
        "symbol": "kr",
        "disambiguation": "NKr"
      },
      "NZD": {
        "icon": "far fa-dollar-sign",
        "symbol": "$",
        "disambiguation": "NZ$"
      },
      "PHP": {
        "icon": "far fa-peso-sign",
        "symbol": "₱",
        "disambiguation": null
      },
      "PLN": {
        "icon": "fas fa-polish-zloty-sign",
        "symbol": "zł",
        "disambiguation": null
      },
      "RON": {
        "icon": "fas fa-romanian-leu-sign",
        "symbol": "Lei",
        "disambiguation": null
      },
      "RSD": {
        "icon": "fas fa-serbian-dinar-sign",
        "symbol": "Дин",
        "disambiguation": null
      },
      "SEK": {
        "icon": "fas fa-kr-sign",
        "symbol": "kr",
        "disambiguation": "Skr"
      },
      "SGD": {
        "icon": "fas fa-singapore-dollar-sign",
        "symbol": "S$",
        "disambiguation": null
      },
      "THB": {
        "icon": "far fa-baht-sign",
        "symbol": "฿",
        "disambiguation": null
      },
      "TRY": {
        "icon": "far fa-turkish-lira-sign",
        "symbol": "₺",
        "disambiguation": null
      },
      "TWD": {
        "icon": "far fa-dollar-sign",
        "symbol": "$",
        "disambiguation": "NT$"
      },
      "USD": {
        "icon": "far fa-dollar-sign",
        "symbol": "$",
        "disambiguation": "US$"
      },
      "ZAR": {
        "icon": "far fa-r",
        "symbol": "R",
        "disambiguation": null
      }
    }
      }
    }
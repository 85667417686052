function UploadForm() {
  // Primary component.
  const $form = $(this)

  const $modelTypeInput = $form.find("input[name=primary_model_type]")
  const $fileInput = $form.find("input[type=file]")
  const $submit = $form.find("input[type=submit]")
  const $fileAttachment = $form.find("[data-upload-form-part=file]")
  const $uploadCsvPrompt = $form.find("[data-upload-form-part=button]")

  const fileOf = ($sel) => $sel[0] && $sel[0].files && $sel[0].files[0]
  const selectedModelType = ($sel) => $sel.filter(":checked").val()

  // Renders a row that shows what file is attached, and an action to remove it
  // and start over.
  function renderAttachmentInfo() {
    if (!$fileAttachment.is(":empty")) return

    $uploadCsvPrompt.hide()

    $fileAttachment.append(`
      <div class="csv-import-uploader__file-badge">
        <i class="far fa-file"></i>
      </div>
      <div class="grow ml-2">
        ${(fileOf($fileInput) || {}).name}
      </div>
      <a
        class="
          self-stretch
          flex
          items-center
          pl-5
        "
        data-upload-form-part="remove-file"
        href="#"
      >
        <i class="far fa-xmark fa-lg"></i>
      </a>
    `)
  }

  // Renders a button that the user can click to attach a file.
  function renderUiToGatherAttachment() {
    $fileInput.val("")

    $fileAttachment.empty().removeClass("disabled")

    $uploadCsvPrompt.removeClass("disabled").show()
  }

  // Updates the UI based on the current form state.
  function renderNextFormUi() {
    const modelType = selectedModelType($modelTypeInput)
    const file = fileOf($fileInput)
    const isIncomplete = !modelType || !file

    if (fileOf($fileInput)) {
      renderAttachmentInfo()
    } else {
      renderUiToGatherAttachment()
    }

    $submit.attr("disabled", isIncomplete).toggleClass("disabled", isIncomplete)
  }

  const clearAndRenderNext = (ev) => {
    ev.preventDefault()
    $fileInput.val("")
    renderNextFormUi()
  }

  // Bind to the hidden file input's field label so we can prevent default click
  // actions when an attachment is present. In these cases, we prefer the user
  // to first clear attached file. Otherwise, preserve the standard behavior of
  // showing a dialog.
  const maybeTriggerDialog = () => !fileOf($fileInput) && $fileInput.trigger("click")

  // Prevents file input field label from showing a file dialog when an
  // attachment is showing.
  const maybePreventDefaultIfAttachmentPresent = (ev) => fileOf($fileInput) && ev.preventDefault()

  // Attach event handlers.
  $form
    .on("change", renderNextFormUi)
    .on("click", "[for=csv]", maybePreventDefaultIfAttachmentPresent)
    .on("click", "[data-upload-form-part=button]", maybeTriggerDialog)
    .on("click", "[data-upload-form-part=remove-file]", clearAndRenderNext)
}

UploadForm.mount = UploadForm

export default UploadForm

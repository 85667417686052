/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
const menuItemHeight = 36

// Drop down menus
class DropdownMenu {
  static handleClick(event) {
    event.stopPropagation()
    event.preventDefault()
    let menu = $(event.currentTarget).siblings(".dropdown-menu")
    // Account for dropdowns from a button nested in a form/ a.k.a rails button_to
    if (!menu || !menu.length) {
      menu = $(event.currentTarget).parent().siblings(".dropdown-menu")
    }
    const hide = menu.is(":visible")

    if ($(".table").find(menu).length || $(".table-with-menu").find(menu).length) {
      const table = $(".table-container")[0] || $(".table")[0] || $(".table-with-menu")[0]
      const tableHeight = table?.getBoundingClientRect().bottom
      const tableTop = table?.getBoundingClientRect().top
      const buttonBoundingClientRect = event.currentTarget.getBoundingClientRect()
      const menuHeight = menu[0].children?.length * menuItemHeight
      const menuBottom = buttonBoundingClientRect.y + buttonBoundingClientRect.height + menuHeight
      const menuTop = buttonBoundingClientRect.y - menuHeight

      // transforms the menu if it needs to be right-aligned
      if (menu.hasClass("dropdown-menu-right")) {
        menu.css({
          right: `calc(${parseInt(menu.css("width"))}px / 2 - ${buttonBoundingClientRect.width}px)`,
        })
      }

      // If the menu is going to overflow the table then flip the menu above, unless it will overflow there as well.
      if (menu.length && tableHeight && tableHeight - menuBottom < 0 && menuTop > tableTop) {
        menu.css({ transform: "translateY(calc(-100% - 2rem))" })
      }
    }

    $(".dropdown-menu").not($(event.currentTarget).parents(".dropdown-menu")).hide()
    if (!hide) {
      return menu.show()
    }
  }

  static handleVisibility(el) {
    $(el).on("click", () => $(".dropdown-menu").hide())

    $(el).on("click", ".dropdown-menu", (e) => e.stopPropagation())

    return $(el).on("click", ".dropdown-menu-link:not(.keep-open)", () =>
      $(el).find(".dropdown-menu").hide(),
    )
  }

  static highlightNext(event) {
    const el = event.currentTarget
    const $menu = $(el).siblings(".dropdown-menu")
    if ($menu.is(":hidden")) {
      return $menu.show()
    }
    const $active = $menu.find(".dropdown-menu-link.active")
    let $next = $active.next()
    if ($next.length === 0) {
      $next = $active.siblings().first()
    }

    $active.removeClass("active")
    return $next.addClass("active")
  }

  static highlightPrev(event) {
    const el = event.currentTarget
    const $active = $(el).siblings(".dropdown-menu").find(".dropdown-menu-link.active")
    let $prev = $active.prev()
    if ($prev.length === 0) {
      $prev = $active.siblings().last()
    }

    $active.removeClass("active")
    return $prev.addClass("active")
  }

  static handleEnter(event) {
    const el = event.currentTarget
    const $active = $(el).siblings(".dropdown-menu").find(".dropdown-menu-link.active")
    return $active.click()
  }
}

jQuery(($) => {
  $.onmount(".dropdown .dropdown-link", function () {
    const $component = $(this)
    $component.on("click", function (event) {
      if (!$component.hasClass("disabled") && !$component.hasClass("readonly")) {
        return DropdownMenu.handleClick(event)
      }
    })
    return $component.on("keyup", (event) => {
      switch (event.keyCode) {
        case 40:
          return DropdownMenu.highlightNext(event)
        case 38:
          return DropdownMenu.highlightPrev(event)
        case 13:
          return DropdownMenu.handleEnter(event)
      }
    })
  })

  DropdownMenu.handleVisibility(document)

  return $.onmount(".tabs-container, .stages-container", function () {
    return $(this).tabDropDownMenu()
  })
})

window.DropdownMenu = DropdownMenu

const $ = window.jQuery
const Keyboard = window.Keyboard

/**
 Decorates an autocomplete widget with the following new capabilities:
 - Only unique entries can be added to the list
 - Clients can trigger a 'people-list-autocomplete:exclude' event against the
 container element to exclude certain people from the list, in case the
 user tries to add an excluded person, it will trigger a validation error.
 Excluded people can be 'added back' by triggering 'people-list-autocomplete:include'
**/
class PeopleListAutoComplete {
  static personDetails(personEl) {
    return [personEl.data("id"), personEl.find(".list-group-item-title").text().trim()]
  }

  constructor($container) {
    this.$container = $container
    this.$list = $container.find(".list-group")
    this.$input = $container.find(".input-group input")

    this.$excludedErr = $container.find(".autocomplete-error__excluded")
    this.$duplicatedErr = $container.find(".autocomplete-error__duplicated")

    this.$selected = null
    this.excluded = []

    this.wireEvents()
  }

  wireEvents() {
    this.$container.on(
      "people-list-autocomplete:exclude",
      (id) => (this.excluded = [...this.excluded, parseInt(id, 10)]),
    )
    this.$container.on(
      "people-list-autocomplete:include",
      (id) => (this.excluded = this.excluded.filter((x) => x !== parseInt(id, 10))),
    )

    this.$container.on("autocomplete:inputChanged", this.clearErrors.bind(this))
    this.$container.on("autocomplete:selectSuggestion", this.onSuggestionSelect.bind(this))

    this.$container.on("click", '[data-action="add-person"]', this.onAddPersonClick.bind(this))
    this.$list.on("click", '[data-action="remove-person"]', this.remove.bind(this))
  }

  clearErrors() {
    this.$container.find(".ui-error").hide()
    this.$container.find(".input-group").removeClass("form-error")
  }

  onAddPersonClick(evt) {
    evt.preventDefault()
    if (this.$selected) {
      this.append(this.$selected)
    }
  }

  validate($person) {
    this.clearErrors()

    const [personId, personFullName] = this.constructor.personDetails($person)
    let isValid = true

    if (this.excluded.includes(personId)) {
      this.$excludedErr.text(this.$excludedErr.data("message").replace("$person", personFullName))
      this.$excludedErr.show()
      isValid = false
    }

    if (this.appended().includes(personId)) {
      this.$duplicatedErr.text(
        this.$duplicatedErr.data("message").replace("$person", personFullName),
      )
      this.$duplicatedErr.show()
      isValid = false
    }

    if (!isValid) {
      this.$container.find(".input-group").addClass("form-error")
    }

    return isValid
  }

  onSuggestionSelect(_, $selected, $event) {
    if ($event.type === "keydown" && Keyboard.keyMatchesName($event.which, "enter")) {
      $event.preventDefault()
      this.append($selected)
    } else {
      this.$selected = $selected
      this.validate($selected)
    }
  }

  remove(e) {
    const $row = $(e.currentTarget.parentElement)
    $row.remove()
    this.$container.trigger("people-list-autocomplete:removed", {
      personId: $row.data("person-id"),
    })
  }

  createRow($person) {
    const [id, fullName] = this.constructor.personDetails($person)
    const idElName = this.$input.attr("name").replace("[person]", "[person_ids][]")
    return `<div class='list-group-item' id=${id} data-person-id=${id}>
      <div>
        ${fullName} <input type='hidden' name='${idElName}' value=${id}>
      </div>
      <a data-action='remove-person'>
        <i class='far fa-xmark' aria-hidden='true'/>
      </a>
    </div>`
  }

  append($person) {
    if (this.validate($person)) {
      this.$list.append(this.createRow($person))
      this.$container.trigger("people-list-autocomplete:added", { personId: $person.data("id") })
      this.$selected = null
      this.$input.val("")
    }
  }

  appended() {
    return this.$list
      .find(".list-group-item input")
      .map(function () {
        return parseInt(this.value, 10)
      })
      .get()
  }
}

$.onmount(
  ".with-people-list-autocomplete",
  () => new PeopleListAutoComplete($(".with-people-list-autocomplete")),
)
